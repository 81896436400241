export default function AddContactPopupController($scope, $state, $mdDialog, ContactAddService, isQuote) {

  'ngInject';

  const vm = this;

  vm.isFromPopup = true;
  vm.isQuote = isQuote;
  vm.stateName = isQuote ? 'quotes' : 'customers';

  ContactAddService.init(vm);

  vm.cancel = function(result) {
    $mdDialog.cancel();

    if (isQuote && result) {
      $state.go('main.quotes.createQuoteGuider', {
        customerId1: result.customerId.primary,
        customerId2: result.customerId.secondary
      });
    }
  };
}
export default function(app) {
  app.constant('uploadValidate',
    'image/*, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/msword'
  ).constant('actionAttachFunctions', {
    20: {
      functionName: 'Quotes',
      cbac: 'quotes'
    },
    45: {
      functionName: 'Opportunities',
      cbac: 'customer_opportunities'
    },
    21: {
      functionName: 'Jobs',
      cbac: 'jobs'
    },
    10: {
      functionName: 'Customer',
      cbac: 'customers'
    },
    69: {
      functionName: 'Campaign',
      cbac: 'customer_campaigns'
    },
    // 24:'Product',
    // 27:'Promotion'
  });
}
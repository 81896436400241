export default function(app) {
  app.factory('AttachCompanyAddressService', AttachCompanyAddressService);

  function AttachCompanyAddressService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage, $rootScope, $mdSidenav, ListService) {
    "ngInject";

    return {
      initList: initList,
      initAddress: initAddress
    }

    function initList(vm) {
      vm.lists = ['customers', 'companies', 'scheduleHistory'];
      vm.listName = vm.lists[0];
      vm.rightSidenavs = ['customers', 'companies', 'scheduleHistory'];

      vm.defaultFc = {
        companies: angular.copy({
          filters: [{
            field: 'isArchived',
            operator: '=',
            value: false
          }],
          defaultSort: [{
            field: 'lastUpdated',
            order: -1
          }]
        }),
        customers: angular.copy({
          filters: [{
            field: 'isArchived',
            operator: '=',
            value: false
          }],
          defaultSort: [{
            field: 'lastUpdated',
            order: -1
          }]
        }),
        scheduleHistory: angular.copy({filters: [
          {
            "field": "activity_type",
            "value": "general",
            "operator": "="
          },
          {
            "field": "action",
            "value": ["reschedule"],
            "operator": "in"
          },
          {
            "field": "model",
            "value": ["job_regular_service", "quote_recurring_service", "quote_regular_service", "job_recurring_service"],
            "operator": "in"
          }
        ],
        defaultSort: [{
          field: "_id",
          order: -1
        }],
        "skip": 0,
        "limit": 15
        })
      };

      // Suggestion and search history api keys required for search functionality in right sidenavs
      vm.suggestionApi = {
        companies: 'companyApi/company/list/name',
      };

      vm.searchHistoryApiKey = {
        companies: 69,
        customers: 69
      };

      // Search functions references for right sidenavs
      vm.toggleSearch = ListService.toggleSearch;
      vm.searchOnEnter = ListService.searchOnEnter;
      vm.getSearchKeys = ListService.getSearchKeys(vm);

      // Common functionality for opening and closing sidenavs
      vm.openSidenav = ListService.openSidenav;
      vm.closeSidenav = ListService.closeSidenav;

      vm.getList = getList;
      vm.search = search;

      // Search: set typed text to local controller variable and call list api
      function search() {
        getList('new', false, false, function() {
          if (vm.searchText && vm.searchText[vm.listName]) {
            ListService.toggleSearch(isSidenav);
          }
        });
      }

      function getList(type = 'new', retainLimit, switchPageType, callback) {
        let url, filterCriteria = ListService.setPrimaryDataCopy(vm, type, retainLimit, switchPageType),
          spinnerName = '';
        if (vm.listName == 'companies') {
          url = serverUrl.main + 'additionalAddressApi/address/company/list?filterCriteria=' + filterCriteria + '&' + (vm.searchText.companies ? ('search=' + vm.searchText.companies + '&') : '');
        } else if (vm.listName == 'customers') {
          url = serverUrl.main + 'contactApi/customers?addressCount=true&filterCriteria=' + filterCriteria + '&' + (vm.searchText.customers ? ('search=' + vm.searchText.customers + '&') : '');
          spinnerName = 'sidebar-spinner';
        } else if(vm.listName == 'scheduleHistory') {
          url = serverUrl.main + 'activityApi/activity?filterCriteria=' + filterCriteria + '&';
          spinnerName = 'schedule-history-spinner';
        }

        ListService.postList(vm, url, vm.enableList, spinnerName, type == 'add', callback);
      }

      vm.setFilters = setFilters;

      function setFilters(type, retainLimit = false, switchPageType = false) {
        vm.filters.companies = angular.copy(vm.defaultFc.companies.filters);
        vm.getList(type, retainLimit, switchPageType);
      }

      ListService.assignedDataCopy(vm, false, [], 'companies', 'id');
      ListService.assignedDataCopy(vm, false, [], 'customers', 'id');
      ListService.assignedDataCopy(vm, false, [], 'scheduleHistory', 'id');
    }

    function initAddress(vm) {

      vm.openAddress = openAddress;
      vm.openContactAddress = openContactAddress;
      vm.getCompanyAddress = getCompanyAddress;
      vm.selectAddressHandler = selectAddressHandler;
      vm.selectContactAddressHandler = selectContactAddressHandler;
      vm.assignAddress = assignAddress;
      vm.getLocationAddress = getLocationAddress;

      vm.addAddress = addAddress;
      vm.addAddressLength = addAddressLength;

      vm.getAdditionalAddresses = () => {
        if (vm.additionalAddresses && vm.additionalAddresses.length) {
          return;
        }

        dataServices.get({
          url: `${serverUrl.main}additionalAddressApi/address/list/${vm.selectedContact.customerId}?type=1&`,
          spinnerName: 'address-select-spinner'
        }).then(response => {
          let data = response.data;
          if (data && data.result) {
            vm.additionalAddresses = data.result.map(item => item.address);
          }
        });
      };

      function addAddress(customer) {

        vm.contactAddresses = [];
        vm.selectedAddressType = 1;
        vm.additionalAddresses = [];
        vm.selectContactAddress = {};
        vm.selectedContact = angular.copy(customer);

        if (angular.isObject(customer.billingAddress) && customer.billingAddress.line1) {
          customer.billingAddress.addressType = 'Billing Address';
          vm.contactAddresses.push(customer.billingAddress);
        }

        if (angular.isObject(customer.mailingAddress) && customer.mailingAddress.line1) {
          customer.mailingAddress.addressType = 'Mailing Address';
          vm.contactAddresses.push(customer.mailingAddress);
        }

        if (angular.isObject(customer.shippingAddress) && customer.shippingAddress.line1) {
          customer.shippingAddress.addressType = 'Shipping Address';
          vm.contactAddresses.push(customer.shippingAddress);
        }

        vm.selectContactAddress = vm.contactAddresses[0]
        console.log(vm.contactAddresses)
      }

      function addAddressLength(customer) {
        let addressCount = 0;
        if (angular.isObject(customer.billingAddress) && customer.billingAddress.line1) {
          addressCount++;
        }
        if (angular.isObject(customer.mailingAddress) && customer.mailingAddress.line1) {
          addressCount++;
        }
        if (angular.isObject(customer.shippingAddress) && customer.shippingAddress.line1) {
          addressCount++;
        }
        return addressCount;
      }

      function openAddress() {
        $mdSidenav('companies-detail').toggle();
      }

      function openContactAddress() {
        $mdSidenav('contact-address-detail').toggle();
      }

      // START: We are fetching the addresses of selected company.
      function getCompanyAddress(companyDetail) {
        vm.openAddress();
        dataServices.get({
          url: serverUrl.main + 'additionalAddressApi/address/company/list?company=' + companyDetail.id + '&',
          spinnerName: 'companies-detail-spinner'
        }).then(response => {
          if (response && response.data && response.data.result) {
            vm.selectCompanyAddress = {};
            vm.selectedCompany = angular.copy(companyDetail);
            vm.companyAddresses = response.data.result;
          }
        });
      }
      // END: We are fetching the addresses of selected company.

      // START: We are attching the address to particular service.
      function selectAddressHandler() {
        if (!vm.selectedCompany) {
          return;
        }
        vm.assignAddress(vm.companyAddressType, vm.selectCompanyAddress.address, vm.selectedCompany.name, vm.selectCompanyAddress.location);

        vm.openAddress();

        if (angular.isFunction(vm.saveCompanyAddress)) {
          vm.saveCompanyAddress();
        }

        vm.closeSidenav('companies');
      }

      function selectContactAddressHandler() {
        if (!vm.selectedContact) {
          return;
        }

        delete vm.selectContactAddress.addressType;
        vm.selectContactAddress.contactName = `${vm.selectedContact.firstName} ${vm.selectedContact.lastName}`;

        vm.assignAddress(vm.companyAddressType, vm.selectContactAddress, vm.selectedContact.company);
        vm.openContactAddress();
        if (angular.isFunction(vm.saveCompanyAddress)) {
          vm.saveCompanyAddress();
        }

        vm.closeSidenav('customers');
      }
      // END: We are attching the address to particular service.

      // START: It is copying the address to particular type like Origin and destination address.
      function assignAddress(addType, fillData, companyName = vm.customerCompanyName, companyLocationName) {
        const locObj = {
          ...fillData,
          companyName: companyName
        };

        if (companyLocationName) {
          locObj.addressName = companyLocationName;
        }

        vm[addType == 'orig' ? 'address' : 'destAddress'] = locObj;
      }
      // END: It is copying the address to particular type like Origin and destination address.

      // START: We are checking which address we need to copy to a particular address type(Origin, Destination).
      function getLocationAddress(addType) {
        let addressType = +vm[addType == 'orig' ? 'selectOriginAddressType' : 'selectDestAddressType'];

        let companyName = addressType == 4 ? (vm.multipleAddress[addressType].companyName || '') : vm.customerCompanyName;

        // fill when not manual or company address
        if (addressType != 5) {
          vm.assignAddress(addType, vm.multipleAddress[addressType], companyName);
        }
        // current location old code
        // else if (addressType == 2) {
        //   geoLocation.getAddress()
        //     .then(function(location) {
        //       if (location) {
        //         vm.assignAddress(addType, location, companyName);
        //       } else {
        //         messages.nativeAlert("NOT_ABLE_TO_FIND_CURRENT_LOCATION");
        //         vm.assignAddress(addType, {}, companyName);
        //       }
        //     });
        // }
        else {
          vm.assignAddress(addType, {}, '');
        }
      }
      // END: We are checking which address we need to copy to a particular address type(Origin, Destination).
    }
  }
}
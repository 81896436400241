import replace from 'lodash/replace';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

import ConfirmTemplate from '../../components/popup-view/confirm-dialog.html';
import editContactPopupTemplate from '../../components/right-quick-menu/add-contact-popup.html';

export default function(app) {
  app.factory('ContactAddService', contactAddService);

  function contactAddService($state, $rootScope, $mdDialog, $mdConstant, sharedDataService, messages, maxlength, imageService, dataServices, serverUrl, regxMatch, CustomFormService, errorMessage, contactData, validatorService, $filter) {

    "ngInject";

    let vm, scope, quoteId, sCon = {},
      bAdd = {},
      detail = {},
      url, paramCustomerId, secondCustomerId;

    return {
      init: initContact,
      save: save,
      editContact: editContact
    };

    function initContact(_vm, _scope, contactDetail) {
      vm = _vm;
      scope = _scope;
      vm.maxlength = maxlength;
      vm.showImagePopUp = imageService.showImagePopUp;

      (function getCategories() {
        dataServices.get({
            url: serverUrl.main + 'customerCatApi/category?',
            spinner: false
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.categories = data.result;
              if (vm.detail.primaryCategoryId == '') {
                vm.detail.primaryCategoryId = vm.categories[0].id;
              }
            }
          });
      })();

      function getCustomForm() {
        let filterCriteria = {
          filters: [{
            field: 'formTarget',
            value: 'contact_form',
            operator: "="
          }, {
            field: 'status',
            value: 2,
            operator: '='
          }]
        };

        dataServices.get({
            url: serverUrl.main + 'customFieldsApi/customFields?filterCriteria=' + angular.toJson(filterCriteria) + '&',
            spinner: false
          })
          .then(function(response) {
            if (response && response.data.response_code == 200) {
              CustomFormService.init(vm, response);
            }
          });
      }

      if (vm.stateName == 'customers') {
        getCustomForm();
      }

      vm.separatorKeys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA];
      vm.countryCodes = $rootScope.aObj.countries;

      // Get current state.
      vm.licenseInfo = [];
      vm.saveContact = saveContact;
      vm.addLicenseInfo = addLicenseInfo;
      vm.removeLicenseInfo = removeLicenseInfo;

      vm.newForm = {};
      vm.multiselect = {};
      vm.regxMatch = regxMatch;

      // Initialize accordion states using an object
      vm.isAccordionOpen = {};

      let isExpandedAll = false;
      vm.toggleAllAccordions = () => {
        // Set all other accordion states to true
        isExpandedAll = !isExpandedAll;
        for (var i = 1; i <= 3; i++) {
          vm.isAccordionOpen['accordion' + i] = isExpandedAll;
        }
        console.log(vm.isAccordionOpen);
      };

      if (contactDetail && contactDetail.data.result) {
        vm.contactDetail = contactDetail.data.result;

        if (scope) {
          scope.$parent.vm.screenData = vm.contactDetail.quoteId + ' - ' + $filter('getFullName')(vm.contactDetail.contact) + ' - ' + (vm.contactDetail.ContactDetail.company ? (vm.contactDetail.ContactDetail.company + ' - ') : '') + (vm.contactDetail.isArchived ? 'Archived' : 'Active');
        }
      } else if (scope && vm.quoteId) {
        scope.$parent.vm.isRequiredApiFailed = vm.isRequiredApiFailed = true;
      }

      // CustomFormService.init(vm, CUSTOM_FORM_CUSTOMER);

      imageService.initImageUpload(vm, 'profileImg');

      vm.selectedCountry = 'the_United_States';

      vm.optionsCity = {
        types: ['(cities)']
      };

      vm.address = [{}];

      vm.errorMessages = errorMessage;

      vm.saveAndNext = (tabToGo) => {
        if (tabToGo) {
          sharedDataService.set('customerProfile', tabToGo);
        } else {
          vm.isSaveAndNext = true;
        }
        validatorService.validateForm(vm, contactDependancy)();
      };

      vm.addEmail = addEmail;
      vm.removeEmail = removeEmail;
      vm.addPhone = addPhone;
      vm.removePhone = removePhone;
      vm.billAddress = billAddress;
      vm.shipAddress = shipAddress;
      // vm.uploadFiles = uploadFiles;
      vm.searchBasedOnZipcode = searchBasedOnZipcode;
      vm.secondaryContact = secondaryContact;
      vm.deleteSecondaryContact = deleteSecondaryContact;
      // vm.cancel = cancel;
      vm.querySearch = querySearch;
      vm.assignSelectedItem = assignSelectedItem;
      // vm.cleanData = cleanData;
      vm.senseChange = senseChange;
      // vm.showUploadImagePopUp = showUploadImagePopUp;
      vm.checkTextisNumber = checkTextisNumber;
      vm.querySearchForCompany = querySearchForCompany;
      // vm.checkAlphabetAndLength = checkAlphabetAndLength;
      vm.checkLength = checkLength;
      vm.checkNumericAndLength = checkNumericAndLength;
      // vm.checkAlphabetAndLengthForCompany = checkAlphabetAndLengthForCompany;
      vm.copyAddress = copyAddress;
      vm.conv_camelcase = conv_camelcase;
      vm.clearAddress = clearAddress;
      vm.isShowClearAddressBtn = isShowClearAddressBtn;

      vm.saveCountryCode = (countryCode) => {
        const localData = sharedDataService.get('localData') || {};
        localData.countryCode = vm.lastCountryCode = countryCode;
        sharedDataService.set('localData', localData);
      };

      (vm.getCountryCode = () => {
        const localData = sharedDataService.get('localData') || {};
        vm.lastCountryCode = localData.countryCode || '';
      })();

      // If billing and sailing address is equal to mailing address, then this function will fire.
      function copyAddress(addressType) {
        vm.detail[addressType] = cloneDeep(vm.detail.mailingAddress);
        vm.address[addressType == 'billingAddress' ? 1 : 2] = vm.detail[addressType];
      }

      function conv_camelcase(txt) {
        return $filter('camelcase')(txt);
      }

      // Autocomplete have some length limitation and character should be alphabet.
      // function checkAlphabetAndLength(keyName, index, length) {
      //   vm.contacts[index][keyName] = replace(vm.contacts[index][keyName], /[0-9]/g, '');
      //   if (length) {
      //     vm.contacts[index][keyName] = vm.contacts[index][keyName].substring(0, length);
      //   }
      // }

      //storing/fetching values to/from local storage

      vm.initializeDropdown = function(dataType, index, defaultValue) {
        let key = `${dataType}_${index}`;
        let storedValue = sharedDataService.get(key);
        vm[dataType] = vm[dataType] || [];
        vm[dataType][index] = vm[dataType][index] || {};
        vm[dataType][index].type = storedValue || defaultValue;
        console.log(vm[dataType][index].type)
      };

      vm.dropdownChanges = {}
      vm.handleDropdownChange = function(dataType, index, selectedValue) {
        // var key = `${dataType}_${index}`;
        if (!vm.dropdownChanges[dataType]) {
          vm.dropdownChanges[dataType] = {};
        }
        vm.dropdownChanges[dataType][index] = selectedValue;
        console.log(vm.dropdownChanges[dataType][index])
      };

      function checkNumericAndLength(keyName, index, length) {
        vm.detail[index][keyName] = replace(vm.detail[index][keyName], /[A-Za-z]/g, '');
        if (length) {
          vm.detail[index][keyName] = vm.detail[index][keyName].substring(0, length);
        }
      }

      function checkLength(keyName, index, length) {
        if (length && vm.contacts[index]) {
          vm.contacts[index][keyName] = vm.contacts[index][keyName].substring(0, length);
        } else {
          vm.detail[index][keyName] = vm.detail[index][keyName].substring(0, length);
        }
      }

      assignedData();

      // Select contact for assign the card
      function querySearchForCompany() {
        let query = vm.detail.company;
        let filterCriteria = angular.toJson({
          filters: [{
            field: 'companyCategory',
            value: 1,
            operator: "="
          }]
        });

        return dataServices.get({
            url: `${serverUrl.main}companyApi/company/list/suggestion?search=${query}&filterCriteria=${filterCriteria}&`,
            spinner: false
          })
          .then(function(response) {
            if (response.data && response.data) {
              return response.data.result;
            }
          });
      }

      vm.querySearchForTitle = querySearchForTitle;

      let titles = [];
      angular.forEach($rootScope.rObj.records.position, function(reason, id) {
        if (reason.enabled && !reason.isArchived) {
          titles.push({
            reason: reason.reason,
            id: reason.id
          });
        }
      });

      function querySearchForTitle(query) {
        console.log(query);
        return query ? filterTitles(query) : titles;
      }

      function filterTitles(query) {
        let lowercaseQuery = query.toLowerCase();

        let result = titles.filter(function(title) {
          return (title.reason.toLowerCase().indexOf(lowercaseQuery) != -1);
        });

        return result;
      }

      // Default Function call set at initial level.
      function checkTextisNumber(contactIndex, length) {
        vm.contacts[contactIndex].phone[0].phone = vm.contacts[contactIndex].phone[0].phone.replace(/\D/g, '').substring(0, length) || '';
      }

      // Reset values.
      function resetData() {
        vm.detail = {
          licenseInfo: [],
          website: [],
          primaryContact: {
            email: [],
            phone: [],
            check: {
              firstName: false,
              lastName: false,
              email: false,
              phone: false
            },
            firstName: '',
            lastName: ''
          },
          primaryCategoryId: '',
          secondaryCategoryId: '',
          mailingAddress: {},
          sContact: false,
          bAddress: false,
          sAddress: false,
          rating: 5,
          name: ''
        };
        if (vm.custId) {
          vm.detail.customerId = vm.custId;
        }
        if (vm.stateName == 'customers') {
          vm.detail.favorite = false;
        }

        angular.copy(vm.detail, detail);
        vm.selectedItem = [];
        vm.selectedItemCompany = undefined;
        vm.selectedItemTitle = undefined;

        vm.queryContact = [];
        vm.contacts = [vm.detail.primaryContact];
        // console.log(vm.detail.primaryContact);
        vm.address = [vm.detail.mailingAddress];
        vm.validateColor = [undefined];

        addEmail(0);
        addPhone(0);
        angular.copy(detail.primaryContact, sCon);
        angular.copy(detail.mailingAddress, bAdd);
      }

      // Contact model
      function assignedData() {
        // vm.screenName = "ADD_CONTACT";
        resetData();
        let data = {};
        // If there have param quoteId then API call for fetching the customer detail.
        if (vm.paramQuoteId) {
          // doNotFetchCustomer = false;

          // Fetch customer detail when Id comes from other Screen.
          let stateName = vm.stateName == 'customers' ? 'customer' : 'quote';

          if (vm.isDraftContact && stateName == 'customer') {
            url = serverUrl.main + 'draftContactApi/draft/contact/' + vm.paramQuoteId + '?';
          } else {
            url = serverUrl.main + "contactApi/customers/" + (vm.stateName == 'customers' ? ('details/' + vm.paramQuoteId) : ("quote/" + vm.paramQuoteId)) + "?flow=" + stateName + "&";
          }
          // console.log(url);
          dataServices.get({
              url: url
            })
            .then(function(response) {
              let data = response.data;
              if (data && (data.response_code == 200 && response.status == 200)) {

                if (!$rootScope.qGlobalDetail) {
                  $rootScope.qGlobalDetail = {};
                }
                $rootScope.qGlobalDetail.contact_detail = data.result;

                if ($rootScope.qGlobalDetail.contact_detail.mailingAddress) {
                  $rootScope.qGlobalDetail.contact_detail.mailingAddress.zip = parseInt($rootScope.qGlobalDetail.contact_detail.mailingAddress.zip);
                }

                vm.userDetail = data.result;

                if (vm.customerId && scope) {
                  if (vm.isDraftContact) {
                    scope.$parent.vm.screenData = vm.userDetail.firstName + ' ' + vm.userDetail.lastName + ' - ' + vm.customerId + ' - ' + 'Draft';
                  } else {
                    scope.$parent.vm.screenData = vm.userDetail.firstName + ' ' + vm.userDetail.lastName + ' - ' + vm.customerId + ' - ' + ((vm.userDetail.isArchived) ? 'Archived' : 'Active');
                  }
                }

                // if(vm.isDraftContact) {
                //   vm.userDetail.email = [];
                //   vm.userDetail.email.push(data.result.email[0]);
                // }

                vm.profileImg.avatar = vm.userDetail.avatar;
                vm.profileImg.images = vm.userDetail.images || [];
                vm.initImg = angular.copy(vm.profileImg.images);
                vm.detail.primaryCategoryId = vm.userDetail.primaryCategoryId;
                vm.detail.secondaryCategoryId = vm.userDetail.secondaryCategoryId;

                if (vm.userDetail.primaryCategory == 'None') {
                  vm.detail.primaryCategoryId = vm.categories[0].id;
                }

                console.log(vm.userDetail.customForm)

                CustomFormService.initForUpdate(vm, vm.userDetail.customForm);

                contactData.getViewContactDetail(vm, data.result);
                paramCustomerId = data.result.customerId;
                secondCustomerId = undefined;

                /**
                 * If there have second contact than fill in second contact
                 * case:- At the case of when seco need to add.
                 * case:- When edit any quote and there have secondContact.
                 */
                if (data && data.result.secondContact) {
                  vm.secondCustomerId = secondCustomerId = angular.copy(data.result.secondContact.customerId);
                  contactData.getViewContactDetail(vm, data.result.secondContact, true);
                }

                if (vm.paramQuoteId.secondary) {

                  url = serverUrl.main + "contactApi/customers/details/" + vm.paramQuoteId.secondary + "/&flow=quote&";
                  dataServices.get({
                      url: url
                    })
                    .then(function(response) {
                      let data = response.data;
                      if (data && (data.response_code == 200 && response.status == 200)) {
                        contactData.getViewContactDetail(vm, data.result, 1);
                        vm.secondCustomerId = secondCustomerId = data.result.customerId;
                        if (scope) {
                          scope.$parent.vm.screenData = data.result.firstName + ' ' + vm.contact.lastName + ' - ' + customerId + ' - ' + ((vm.contact.isArchived) ? 'Archived' : 'Active');
                        }
                      }
                    });
                }
              }
            });
        } else {
          $rootScope.qGlobalDetail = undefined;
          // canvasLoader(false, false);
          vm.licenseInfo = [{
            info: ''
          }];

        }
      }

      if (vm.custId) {
        dataServices.get({
            url: serverUrl.main + 'contactApi/customers/details/' + vm.custId + '?segments=yes&'
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.contact = data.result;
              assignSelectedItem();
            }
          });
      }

      // If select any list from autocomplete, then this function will call.
      function assignSelectedItem(fieldName, contactIndex) {
        console.log(contactIndex, vm.selectedItem[contactIndex]);
        if (angular.isDefined(contactIndex) && vm.selectedItem[contactIndex] && vm.selectedItem[contactIndex].customerId) {
          if (contactIndex) {
            vm.secondCustomerId = secondCustomerId = vm.selectedItem[contactIndex].customerId;
          } else {
            paramCustomerId = vm.selectedItem[contactIndex].customerId;
            vm.profileImg.avatar = vm.selectedItem[contactIndex].avatar;
            vm.profileImg.images = vm.selectedItem[contactIndex].images || [];
            vm.initImg = angular.copy(vm.profileImg.images);
          }
          contactData.getViewContactDetail(vm, vm.selectedItem[contactIndex], contactIndex);
        } else if (vm.custId) {
          vm.secondCustomerId = secondCustomerId = vm.custId;
          contactData.getViewContactDetail(vm, vm.contact);
        } else {
          let url;
          if (vm.isDraftContact) {
            url = "draftContactApi/draft/contact/" + vm.selectedItem[contactIndex].customerId;
          } else {
            url = "contactApi/customers/details/" + vm.selectedItem[contactIndex].customerId + "?flow=customer&";
          }
          dataServices.get({
              url: serverUrl.main + url,
              spinnerName: $rootScope.customSpinnerName
            })
            .then(function(response) {
              let data = response.data;
              if (data && (data.response_code == 200 && response.status == 200)) {
                if (contactIndex) {
                  vm.secondCustomerId = secondCustomerId = data.result.customerId;
                } else {
                  paramCustomerId = data.result.customerId;
                  vm.profileImg.avatar = data.result.avatar;
                  vm.profileImg.images = data.result.images || [];
                  vm.initImg = angular.copy(vm.profileImg.images);
                }
                contactData.getViewContactDetail(vm, data.result, contactIndex);
              }
            });
        }
      }

      vm.emailExists = {};

      function querySearch(fieldName, index, query) {
        /*In this conition have 4 different option
        1. doNotFetchCustomer should be true. When need to edit quote.
        2. Previous String should be valid and Previous Response Code 500:- case:- If substring have no result then super set also will have no result.
        3. If user doing backspace then. Go to last string when User get result.
        4. If first time user not getting result. Then using back space go non-result string.*/

        console.log(fieldName, query);

        if (vm.contacts && vm.contacts[index] && vm.contacts[index].check) {
          vm.contacts[index].check.fieldName = true;
        }

        if (!query) {
          return [];
        } else {
          vm.fieldName = fieldName;
          let isAllFieldsSearch = fieldName === 'firstName';
          let filters = isAllFieldsSearch ? [] : [{
            "field": fieldName,
            "value": encodeURIComponent(query),
            "operator": "startI"
          }];
          let filterCriteria = angular.toJson({
            filters,
            skip: 0,
            limit: 10
          });
          let type = isAllFieldsSearch ? 'list' : 'sugg';
          // previousString = query;
          return dataServices.get({
              url: serverUrl.main + 'contactApi/customers?flow=quote&filterCriteria=' + filterCriteria + '&type=' + type + '&' + (isAllFieldsSearch ? ('search=' + encodeURIComponent(query) + '&') : ''),
              spinner: false
            })
            .then(function(response) {
              let data = response.data;
              if (data && (data.response_code == 200 && response.status == 200)) {
                vm.queryContact = data.result;
              } else {
                vm.queryContact = [];
              }

              if (vm.contacts && vm.contacts[index] && vm.contacts[index].check) {
                vm.contacts[index].check.fieldName = false;
              }

              if (fieldName == 'email.id' && !vm.custId) {
                vm.emailExists[index] = data.result.filter(contact => query === contact.email[0].id).length;
                console.log(vm.emailExists);
              }
              return vm.queryContact;
            });
        }
      }

      vm.saveAsDraft = () => {
        validatorService.validateForm(vm, contactDependancy)();
      };

      function saveDraftContact(detail) {
        console.log(angular.toJson(detail.contact));
        dataServices.put({
          url: serverUrl.main + 'draftContactApi/draft/contact/' + vm.customerId,
          data: {
            data: angular.toJson(detail.contact)
          }
        }).then(response => {
          let data = response.data;
          if (data && ((data.response_code == 200 || data.response_code == 201) && response.status)) {
            messages.simpleToast("Contact saved as draft");
            $state.go('main.customers.customers');
          }
        })
      }

      // create ship address and delete in toggle.
      function shipAddress() {
        if (vm.detail.sAddress) {
          vm.address.splice(vm.detail.bAddress ? 2 : 1, 1);
          delete vm.detail.shippingAddress;
          vm.validateColor.splice(vm.detail.bAddress ? 2 : 1, 1);
        } else {
          vm.detail.shippingAddress = {};
          angular.copy(bAdd, vm.detail.shippingAddress);
          vm.address.push(vm.detail.shippingAddress);
          vm.validateColor.push(undefined);
        }
        vm.detail.sAddress = !vm.detail.sAddress;
      }

      // Same for billing address
      function billAddress() {
        if (vm.detail.bAddress) {
          vm.address.splice(1, 1);
          delete vm.detail.billingAddress;
          vm.validateColor.splice(1, 1);
        } else {
          vm.detail.billingAddress = {};
          angular.copy(bAdd, vm.detail.billingAddress);
          vm.address.splice(1, 0, vm.detail.billingAddress);
          vm.validateColor.push(undefined);
        }
        vm.detail.bAddress = !vm.detail.bAddress;
      }

      // Previously this function is working. But now not
      function searchBasedOnZipcode(type, index) {
        var addressObj = type ? vm.detail.addresses : vm.address;
        url = serverUrl.main + "addressApi/zipDetail?zipCode=" + addressObj[index].zip + "&";
        addressObj[index].searchZipcode = true;
        $q.when(dataServices.get({
            url: url
          }, false))
          .then(function(response) {
            let data = response.data;
            if (data && (data.response_code == 200 && response.status == 200)) {
              addressObj[index].city = data.result.city;
              addressObj[index].state = data.result.state;
              addressObj[index].country = data.result.country;
            } else {
              addressObj[index].city = '';
              addressObj[index].state = '';
              addressObj[index].country = '';
            }
            addressObj[index].searchZipcode = false;
          });
      }

      // Add and remove secondary contact.
      // It save in vm.contact var. If there have only primary address then, 1st index only filled, for secondary contact, 2nd index will fill for secondary contact.
      function secondaryContact() {
        // alert('add new contact');
        if (vm.detail.sContact) {
          vm.contacts.splice(1, 1);
          delete vm.detail.secondContact;
          vm.selectedItem.splice(1, 1);
        } else if (vm.contacts.length < 2) {
          vm.detail.secondContact = {};
          angular.copy(sCon, vm.detail.secondContact);
          addEmail(1);
          addPhone(1);
          vm.contacts.push(vm.detail.secondContact);
          vm.selectedItem.push(null);
        }
        vm.detail.sContact = !vm.detail.sContact;
      }

      function deleteSecondaryContact() {

        if (secondCustomerId) {
          vm.secondCustomerId = secondCustomerId = undefined;
        }

        if (vm.detail.secondContact) {
          vm.contacts.splice(1, 1);
          vm.detail.secondContact = null;
          vm.selectedItem.splice(1, 1);
        }
        vm.detail.sContact = !vm.detail.sContact;
      }

      vm.clearSecondaryContact = () => {
        deleteSecondaryContact();
        secondaryContact();
      };

      // Add multiple email based on index.
      function addEmail(contactIndex) {
        // console.log(contactIndex);
        var contactName = contactIndex ? 'secondContact' : 'primaryContact';
        var flag = true;
        forEach(vm.detail[contactName].email, function(val) {
          // alert(val.id);
          if (!val.id) {
            flag = false;
          }
        });
        // alert(flag);
        if (flag) {
          vm.detail[contactName].email.push({
            type: Object.keys($rootScope.aObj.emailType)[0],
            id: ''
          });
        } else {
          messages.nativeAlert("FILL_ALL_EMAIL_FIELDS");
        }
        // console.log(angular.copy(vm.contacts), vm.contacts);
        // console.log(angular.copy(vm.detail), vm.detail);
      }

      // remove email based on index.
      function removeEmail(contactIndex, index) {
        // alert('remove called');
        // alert()
        vm.detail[contactIndex ? 'secondContact' : 'primaryContact'].email.splice(index + 1, 1);
      }

      vm.transformChip = function(chip) {
        // /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
        return /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(chip) ? (chip.startsWith('http://') ? ('https://' + chip.slice(7)) : (chip.startsWith('https://') ? chip : ('https://' + chip))) : null;
      };

      // Add multiple phone.
      function addPhone(contactIndex) {
        var contactName = contactIndex ? 'secondContact' : 'primaryContact',
          flag = true;
        forEach(vm.detail[contactName].phone, function(val) {
          if (!val.phone) {
            flag = false;
          }
        });

        if (flag) {
          vm.detail[contactName].phone.push({
            type: Object.keys($rootScope.aObj.phonetype)[0],
            phone: '',
            countryCode: vm.lastCountryCode
          });
        } else {
          messages.nativeAlert("FILL_ALL_PHONE_NUMBER_FIELDS");
        }
      }

      // Remove phone.
      function removePhone(contactIndex, index) {
        vm.detail[contactIndex ? 'secondContact' : 'primaryContact'].phone.splice(index + 1, 1);
      }

      function senseChange(index) {
        vm.validateColor[index] = false;
      }

      function clearAddress(index) {
        vm.address[index] = {};
      }

      function isShowClearAddressBtn(index) {
        if (angular.toJson(vm.address[index]) == angular.toJson({
            searchZipcode: false
          })) {
          return false
        }
        return true;
      }

      function addLicenseInfo() {
        vm.licenseInfo.push({
          'info': ''
        });
      }

      function removeLicenseInfo(index) {
        vm.licenseInfo.splice(index, 1);
      }

      vm.deleteDraftContact = () => {
        console.log(serverUrl.main + 'draftContactApi/draft/contact/' + vm.customerId);
        // return;
        dataServices.delete({
            url: serverUrl.main + 'draftContactApi/draft/contact/' + vm.customerId
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              messages.simpleToast('Contact Deleted Successfully.');
              $state.go('main.customers.customers');
            }
          });
      }
    }

    function save() {
      if (vm.isDraftContact) {
        console.log("draft");
        vm.saveAsDraftOnly = true;
        vm.saveAsDraft();
      } else {
        console.log("active");
        sharedDataService.set('customerProfile', 'detail');
        validatorService.validateForm(vm, contactDependancy)();
      }
    }

    function confirmPopup() {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: confirmController,
        controllerAs: 'cd',
        templateUrl: ConfirmTemplate,
        parent: angular.element(document.body),
        locals: {
          vm: vm
        },
      });
    }

    function confirmController($mdDialog, $rootScope, vm) {

      'ngInject';

      let cd = this;

      cd.confirm = confirm;
      cd.question = "Do you want to update customer ? ";

      cd.cancel = function() {
        $mdDialog.cancel();
      };

      function confirm() {
        vm.saveContact(cd.cancel);
      }
    }

    function contactDependancy() {
      if (vm.isDraftContact) {
        validatorService.validateForm(vm, saveContact)();
      } else if (!vm.customerId) {
        // START : if you are creating new customer.
        saveContact();
      } else {
        // START : if you are updating customer then need to check dependancy.
        dataServices.get({
          url: serverUrl.main + 'contactApi/customer/dependency/' + vm.customerId + '?'
        }).then(function(response) {
          if (response.data && response.data.result) {
            // having dependancy
            vm.dependency = response.data.result;
            confirmPopup();
          } else if (response.data && response.data.result == false) {
            // NO dependancy
            saveContact();
          }
        });
      }
    }

    function saveContact(isCallback) {

      let detail = contactData.setSaveContactDetail(vm.detail, vm.selectedItem);
      detail.quote.quoteId = vm.paramQuoteId;

      // companyName: id of company
      // company: name of company
      if (vm.selectedItemCompany && vm.selectedItemCompany.id) {
        detail.contact.companyName = vm.selectedItemCompany.id;
        delete detail.contact.company;
      } else if (vm.detail.company) {
        detail.contact.company = vm.detail.company;
      }

      if (vm.detail.title) {
        detail.contact.title = vm.detail.title;
      }
      console.log(vm.selectedItemTitle, vm.detail.title);
      // return;

      console.log(vm.detail.billingAddress, vm.detail.shippingAddress);
      detail.contact.mailingAddress = angular.copy(vm.address[0]);

      if (vm.detail.billingAddress) {
        detail.contact.billingAddress = angular.copy(vm.address[1]);
      } else if (vm.detail.shippingAddress) {
        detail.contact.shippingAddress = angular.copy(vm.address[1]);
      }

      if (vm.detail.billingAddress && vm.detail.shippingAddress) {
        detail.contact.shippingAddress = angular.copy(vm.address[2]);
      }

      detail.contact.email = detail.contact.email.map((email) => {
        return {
          ...email,
          id: email.id.toLowerCase().trim()
        };
      });

      if (detail.contact.mailingAddress && !Object.keys(detail.contact.mailingAddress).length) {
        delete detail.contact.mailingAddress;
      }

      if (detail.contact.billingAddress && !Object.keys(detail.contact.billingAddress).length) {
        delete detail.contact.billingAddress;
      }

      if (detail.contact.shippingAddress && !Object.keys(detail.contact.shippingAddress).length) {
        delete detail.contact.shippingAddress;
      }

      detail.contact.customerId = paramCustomerId;
      if (detail.contact.secondContact && secondCustomerId) {
        detail.contact.secondContact = {
          customerId: secondCustomerId
        };
      }

      // detail.quote.name = vm.detail.name;
      if (vm.stateName == 'customers') {
        delete detail.quote;
        detail.contact['licenseInfo'] = [];
        angular.forEach(vm.licenseInfo, function(val, index) {
          if (val.info != '') {
            detail.contact.licenseInfo.push(val.info);
          }
        });
      }

      delete detail.contact.check;
      if (detail.contact.secondContact) {
        delete detail.contact.secondContact.check;
      }

      // images and avatar
      detail.contact.avatar = vm.profileImg.avatar;

      if (!vm.croppedImg[0]) {
        delete detail.contact.images;
        if (vm.removedImage[0]) {
          detail.contact.removeImages = vm.initImg[0].id;
        }
      } else {
        detail.contact.images = vm.profileImg.images[0];
      }

      if (vm.dob) {
        detail.contact.dob = new Date(vm.dob) / 1000;
      }

      // detail.contact.firstName = conv_camelcase(detail.contact.firstName);
      // detail.contact.lastName = conv_camelcase(detail.contact.lastName);
      // detail.contact.middleName = conv_camelcase(detail.contact.middleName);

      if (detail.contact && angular.isArray(detail.contact.phone) && detail.contact.phone.length) {
        detail.contact.phone = detail.contact.phone.filter(function(item) {
          return item.phone && item.countryCode;
        });
        // for (let i = 0; i < detail.contact.phone.length; i++) {
        //   if (detail.contact.phone[i].phone != "") {
        //     detail.contact.phone[i].countryCode = vm.countryCodes[detail.contact.phone[i].countryCode].code;
        //   }
        // }
      }

      // console.log(detail.contact);
      if (detail.contact && detail.contact.secondContact && angular.isArray(detail.contact.secondContact.phone) && detail.contact.secondContact.phone.length) {
        detail.contact.secondContact.phone = detail.contact.secondContact.phone.filter(function(item) {
          return item.phone && item.countryCode;
        });
        // forEach(detail.contact.secondContact.phone, function(val) {
        //   if (val.phone != "") {
        //     val.countryCode = vm.countryCodes[val.countryCode].code;
        //   }
        // });
      }

      let stateName = vm.stateName == 'quotes' ? 'quote' : 'customers';
      if (stateName == 'customers') {
        if (vm.isDraftContact && vm.saveAsDraftOnly) {
          saveDraftContact(detail);
        } else {
          CustomFormService.beforeSave(vm, detail.contact).then(function(_data) {
            if (vm.isDraftContact) {
              delete detail.contact.customerId
            }
            saveData(stateName, detail, isCallback);
          }, function(error) {
            console.log(error);
          });
        }
      } else {
        saveData(stateName, detail, isCallback);
      }
    }

    function saveData(stateName, detail, isCallback) {
      if (vm.custId) {
        detail.contact.customerId = vm.custId;
      }
      let data = {
        data: angular.toJson(detail),
        flow: stateName,
        ...(vm.isDraftContact && {
          saveToActiveContacts: 'yes'
        })
      };

      console.log(data);
      // return;
      dataServices.post({
          url: serverUrl.main + "contactApi/customers",
          data: data
        })
        .then(function(response) {
          angular.forEach(vm.dropdownChanges, function(entries, dataType) {
            angular.forEach(entries, function(value, index) {
              var key = `${dataType}_${index}`;
              sharedDataService.set(key, value);
            });
          });
          console.log(vm.dropdownChanges);

          let data = response.data;
          if (data && ((data.response_code == 200 || data.response_code == 201) && response.status)) {

            if (vm.isFromPopup) {
              vm.cancel(data.result);
              return;
            }

            if (vm.currentStateName == 'main.customers.addContact' || vm.currentStateName == 'main.customers.editContact' || vm.currentStateName == 'main.customers.editDraftContact') {
              // localStorage.customerProfile = 'detail';
              if (isCallback) {
                isCallback();
              }

              if (vm.isSaveAndNext) {
                messages.simpleToast('Contact Added Successfully.');
                $state.go('main.customers.addContact', {}, {
                  reload: 'main.customers.addContact'
                });

              } else {
                if (vm.isFromOpportunities) {
                  scope && scope.$parent.vm.backScreen(true);
                } else {
                  $state.go('main.customers.customersDetail', {
                    customerId: data.result.customerId
                  });
                }
              }
            } else {
              if (vm.currentStateName == 'main.quotes.addQuote') {
                $state.go('main.quotes.createQuoteGuider', {
                  customerId1: data.result.customerId.primary,
                  customerId2: data.result.customerId.secondary
                });
              } else {
                if (vm.isList) {
                  $state.go('main.' + (vm.contactDetail.isJob ? 'jobList' : 'quoteList'));
                } else {
                  $state.go('main.' + (vm.contactDetail.isJob ? 'jobs' : 'quotes') + '.contact', {
                    quoteId: vm.quoteId
                  });
                }
              }
            }
          }
        });
    }

    function editContact(ev, record) {
      $mdDialog.show({
        locals: {
          record
        },
        targetEvent: ev,
        controller: EditContactPopupController,
        templateUrl: editContactPopupTemplate,
        parent: angular.element(document.body),
        controllerAs: 'vm',
        fullscreen: true,
        multiple: true
      });
    }

    function EditContactPopupController($scope, $state, $mdDialog, ContactAddService, record) {

      'ngInject';

      const vm = this;

      vm.isFromPopup = true;
      vm.stateName = 'customers';
      vm.customerId = vm.paramQuoteId = record.customerId;

      initContact(vm);

      vm.cancel = function(result) {
        if (result) {
          messages.simpleToast('Contact updated successfully.');
        }
        $mdDialog.cancel();
      };
    }
  }
}